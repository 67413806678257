const loggingBaseUrl =
  'https://dev.core-gateway.np.api-business.comcast.com/logging-api';

export default {
  fidoConfig: {
    headers: {
      'tracking-id': '',
      'x-api-key': 'tgnvhW9jET8J9pQT3iET36M3DvVYRgCw5EbBwSjo',
    },
    dataApiConfig: {
      logging: {
        baseUrl: loggingBaseUrl,
        sensitiveFields: null,
      },
      deviceActivation: {
        baseUrl: 'https://device-activation-qa.np.api-business.comcast.com/api/',
        sensitiveFields: ['accountNumber', 'captchaToken', 'validationToken'],
      },
    },
  },
  loggerConfig: {
    appSettings: {
      applicationId: 'device-activation',
      apiKey: 'rIYwDjmhEzaYwpKjeMBRF6Lu1i05OzAE1UIE76RB',
      appVersion: process.env.REACT_APP_UI_VERSION,
      loggingEndpoint: {
        url: '/event',
        baseURL: loggingBaseUrl,
      },
    },
    environment: 'qa',
    visitorSessionId: '',
  },
  sessionDurationInMinutes: 20,
  activationUrl: 'https://register-st.be.xfinity.com/activateCBDevice',
};
